
import Footer from "@/components/Footer";
import LayoutMain from "@/layouts/LayoutMain"

import { blogItem, blogItems } from "@/lib/cnts";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export default function BlogPage() {
  return (
    <LayoutMain>
        <div className="max-w-[56rem] w-full">
          <h1 className="text-5xl font-bold mb-4">Blog</h1>
          <h2 className="text-xl text-gray-400 mb-12">Insights, thoughts and reflections</h2>

          <div className="space-y-12">
            {blogItems.map((item: blogItem) => (
                <article key={item.id} className="space-y-4 bg-zinc-800 p-6 rounded-2xl flex flex-col">
                  <div className="text-gray-400">{item.date}</div>
                  <h3 className="text-2xl font-semibold">{item.title}</h3>
                  <p className="text-gray-400">
                    {item.desc}
                  </p>
                  <Button variant="outline" className="bg-color-black self-start" asChild>
                    <Link to="#">{item.buttonTitle}</Link>
                  </Button>
              </article>
            ))}
          </div>

          <Footer />
        </div>
    </LayoutMain>
  )
}