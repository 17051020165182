import { Copy } from "lucide-react"
import { useState } from "react"


import LayoutMain from "@/layouts/LayoutMain"

import { blogItem, blogItems } from "@/lib/cnts";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import Footer from "@/components/Footer";

export default function ContactPage() {

  const [copied, setCopied] = useState(false)

  const handleCopyEmail = async () => {
    await navigator.clipboard.writeText("brutsky.ms@gmail.com")
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <LayoutMain>
        <div className="max-w-[56rem] w-full">
          <h1 className="text-5xl font-bold mb-4">Contact</h1>
          <h2 className="text-xl text-gray-400 mb-12">Get in touch with me</h2>

          <div className="space-y-8">
            <div>
              <p className="text-gray-400 text-sm mb-2">E-mail</p>
              <div className="flex items-center gap-4">
                <span className="text-lg">
                  <Link to="mailto:brutsky.ms@gmail.com" >
                  brutsky.ms@gmail.com
                  </Link>
                </span>
                <Button variant="outline" size="sm" onClick={handleCopyEmail} className={`gap-2 text-white bg-color-black hidden sm:flex`}>
                  <Copy className="h-4 w-4" />
                  {copied ? "Copied!" : "Copy email"}
                </Button>
              </div>
            </div>

            <div>
              <p className="text-gray-400 text-sm mb-2">Telegram</p>
              <Link to="https://t.me/mbrutsky" className="text-lg hover:text-gray-300">
                https://t.me/mbrutsky
              </Link>
            </div>
          </div>

          <Footer />

        </div>
    </LayoutMain>
  )
}