"use client";

import { Home, User, Briefcase, FolderGit2, ShoppingBag, PenTool, Mail } from "lucide-react"
import { ReactNode } from "react";


export const ctns = {
    name: "Maxim Brutsky",
    jobTitle: "Web Developer",
    skills: [
        {
            id: 0,
            title: "Frontend Development",
            subTitle: "React, Next.js, TypeScript",
        },
        {
            id: 1,
            title: "Backend Development",
            subTitle: "Node.js, Nest.js, Python",
        },
        {
            id: 2,
            title: "Database",
            subTitle: "PostgreSQL, MongoDB",
        },
        {
            id: 3,
            title: "Cloud Services",
            subTitle: "Yandex Cloud, Google Cloud",
        },
        {
            id: 4,
            title: "DevOps",
            subTitle: "Docker, Kubernetes",
        },
        {
            id: 5,
            title: "Tools",
            subTitle: "Git, JIRA, Figma",
        }
    ],
    education: [
        {
            id: 0,
            date: "2010 - 2014",
            title: "BSc Computer Science",
            subTitle: "University of Edinburgh"
        }
    ],
    experience: [
        {
            id: 0,
            date: "2024 - Present",
            title: "FullStack Developer",
            subTitle: "IBS",
        },
        {
            id: 1,
            date: "2020 - 2024",
            title: "Frontend Developer",
            subTitle: "Freelance",
        },
    ],
    awards: [
        {
            id: 0,
            title: "Independent Web Developer of the Year 2022",
            subTitle: "Technology Innovator Awards"
        }
    ]
}

export interface blogItem {
    id: number;
    title: string;
    desc: string;
    link: string;
    buttonTitle: string;
    date: string;
  }
  
export const blogItems: blogItem[] = [
    {
      id: 0,
      title: 'Stripe acquires Lemon Squeezy',
      desc: 'In an unexpected turn of events, Stripe has acquired Lemon Squeezy 🎉',
      link: '#',
      buttonTitle: 'Read More',
      date: 'Aug 1, 2024'
    },
    {
      id: 1,
      title: 'Stripe acquires Lemon Squeezy',
      desc: 'In an unexpected turn of events, Stripe has acquired Lemon Squeezy 🎉',
      link: '#',
      buttonTitle: 'Read More',
      date: 'Aug 1, 2024'
    },
    {
      id: 2,
      title: 'Stripe acquires Lemon Squeezy',
      desc: 'In an unexpected turn of events, Stripe has acquired Lemon Squeezy 🎉',
      link: '#',
      buttonTitle: 'Read More',
      date: 'Aug 1, 2024'
    },
    {
      id: 3,
      title: 'Stripe acquires Lemon Squeezy',
      desc: 'In an unexpected turn of events, Stripe has acquired Lemon Squeezy 🎉',
      link: '#',
      buttonTitle: 'Read More',
      date: 'Aug 1, 2024'
    }
  ]

export interface MenuListItem {
    id: number;
    title: string;
    link: string;
    icon: ReactNode
}

export const menuList: MenuListItem[] = [
    {
        id: 0,
        title: 'Home',
        link: '/',
        icon: <Home className="h-5 min-w-5" />,
    },
    {
        id: 1,
        title: 'About',
        link: '/about',
        icon: <User className="h-5 min-w-5" />
    },
    // {
    //     id: 2,
    //     title: 'Work',
    //     link: '/work',
    //     icon: <Briefcase className="h-5 min-w-5" />
    // },
    // {
    //     id: 3,
    //     title: 'Projects',
    //     link: '/projects',
    //     icon: <FolderGit2 className="h-5 min-w-5" />
    // },
    // {
    //     id: 4,
    //     title: 'Store',
    //     link: '/store',
    //     icon: <ShoppingBag className="h-5 min-w-5" />
    // },
    // {
    //     id: 5,
    //     title: 'Blog',
    //     link: '/blog',
    //     icon: <PenTool className="h-5 min-w-5" />
    // },
    {
        id: 6,
        title: 'Contact',
        link: '/contact',
        icon: <Mail className="h-5 min-w-5" />,
    },
]