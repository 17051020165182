
import { Card } from "@/components/ui/card"
import LayoutMain from "@/layouts/LayoutMain"

import { Link } from "react-router-dom"
import Footer from "@/components/Footer"

export default function StorePage() {
  return (
    <LayoutMain>
        <div className="max-w-[56rem] w-full">
          <h1 className="text-5xl font-bold mb-4">Store</h1>
          <h2 className="text-xl text-gray-400 mb-12">Software and resources I sell</h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Splashify Preview"
                  className="w-full h-48 object-cover"
                  height="200"
                  src="/rob-potter-KuI8_i40SUg-unsplash.jpg"
                  style={{
                    background: "linear-gradient(to bottom right, #818CF8, #6366F1)"
                  }}
                  width="400"
                />
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold text-white">Splashify</h3>
                      <p className="text-gray-400">Stunning desktop wallpapers for Mac & Windows</p>
                    </div>
                    <div className="text-xl font-bold text-white">£2.99</div>
                  </div>
                </div>
              </Card>
            </Link>

            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Neon Shapes Preview"
                  className="w-full h-48 object-cover"
                  height="200"
                  src="/rob-potter-KuI8_i40SUg-unsplash.jpg"
                  style={{
                    background: "linear-gradient(to bottom right, #EC4899, #8B5CF6)"
                  }}
                  width="400"
                />
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold text-white">Neon Shapes Wallpapers</h3>
                      <p className="text-gray-400">Six stunning wallpapers of abstract glass shapes and neon colours</p>
                    </div>
                    <div className="text-xl font-bold text-white">£5</div>
                  </div>
                </div>
              </Card>
            </Link>

            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Arya Template Preview"
                  className="w-full h-48 object-cover"
                  height="200"
                  src="/karsten-winegeart-LbtGpw6iid0-unsplash.jpg"
                  style={{
                    background: "linear-gradient(to bottom right, #F472B6, #DB2777)"
                  }}
                  width="400"
                />
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold text-white">Arya - Designer Portfolio Framer Template</h3>
                      <p className="text-gray-400">A sleek Framer website template for graphic designers, featuring a modern, minimal style</p>
                    </div>
                    <div className="text-xl font-bold text-white">£9</div>
                  </div>
                </div>
              </Card>
            </Link>

            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Arya Template Preview"
                  className="w-full h-48 object-cover"
                  height="200"
                  src="/karsten-winegeart-LbtGpw6iid0-unsplash.jpg"
                  style={{
                    background: "linear-gradient(to bottom right, #F472B6, #DB2777)"
                  }}
                  width="400"
                />
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold text-white">Arya - Designer Portfolio Framer Template</h3>
                      <p className="text-gray-400">A sleek Framer website template for graphic designers, featuring a modern, minimal style</p>
                    </div>
                    <div className="text-xl font-bold text-white">£9</div>
                  </div>
                </div>
              </Card>
            </Link>
          </div>

          <Footer />
        </div>
    </LayoutMain>
  )
}