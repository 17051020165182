"use client"

import { ctns } from '@/lib/cnts'
import React from 'react'

export default function Footer() {
  return (
        <footer className="mt-16">
            <p className="text-gray-500 text-sm">Copyright © {ctns.name}</p>
        </footer>
  )
}