
import { ExternalLink } from "lucide-react"
import LayoutMain from "@/layouts/LayoutMain"


import { Link } from "react-router-dom"
import Footer from "@/components/Footer"

const projects = [
  {
    id: 0,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 1,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 2,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 3,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 4,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 5,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 6,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 7,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  },
  {
    id: 8,
    title: "911 Models",
    subTitle: "Porsche 911 models microsite",
    date: "Launched: Jul 1, 2024",
    link: "#"
  }
]

export default function ProjectsPage() {
  return (
    <LayoutMain>
        <div className="max-w-[56rem] w-full">
          <h1 className="text-5xl font-bold mb-4">Projects</h1>
          <h2 className="text-xl text-gray-400 mb-12">Side projects and businesses I've launched over the years</h2>

          <div className="space-y-4">
            {projects.map((item) => (
              <Link
              key={item.id}
              to="#"
              className="block p-6 bg-zinc-800 rounded-lg hover:bg-zinc-700 transition-colors"
            >
              <div className="flex items-start justify-between">
                <div className="flex gap-4">
                  <div className="w-3 h-3 rounded-full bg-green-500 mt-2" />
                  <div>
                    <h3 className="text-lg font-semibold">{item.title}</h3>
                    <p className="text-gray-400">{item.subTitle}</p>
                    <p className="text-gray-500 text-sm mt-1">{item.date}</p>
                  </div>
                </div>
                <ExternalLink className="h-5 w-5 text-gray-400 hover:stroke-slate-200" />
              </div>
            </Link>
            ))}


          </div>

          <Footer />
        </div>
    </LayoutMain>
  )
}