import Sidebar from '@/components/Sidebar';
import { ReactNode, useState } from 'react';
import { useIsMobile } from '@/lib/useIsMobile';
import { Button } from '@/components/ui/button';

export default function LayoutMain({ children }: { children: ReactNode }) {
    const isMobile = useIsMobile();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => setSidebarOpen((prev) => !prev);

    return (
        <div className="min-h-screen bg-black text-white flex relative">
            {/* Sidebar или кнопка для мобильных устройств */}
            {isMobile ? (
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={toggleSidebar}
                    className="absolute top-4 left-4 z-20 bg-zinc-800 text-white rounded-full"
                >
                    ☰
                    <span className="sr-only">Toggle sidebar</span>
                </Button>
            ) : (
                <Sidebar />
            )}

            {/* Sidebar на весь экран для мобильных устройств */}
            {isMobile && sidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-90 z-10 flex items-center justify-center md:hidden"
                    onClick={toggleSidebar}
                >
                    <Sidebar />
                </div>
            )}

            {/* Main Content */}
            <main
                className={`flex-1 flex p-8 pt-32 md:m-4 bg-zinc-900 rounded-2xl block-anim ml-0 md:ml-[272px] justify-center`}
            >
                {children}
            </main>
        </div>
    );
}
