"use client";

import { Mail, Twitter, Send, Moon, Github } from "lucide-react"

import { Button } from "../ui/button";
import { useLocation } from 'react-router-dom';
import { ctns, menuList, MenuListItem } from "@/lib/cnts";
import { Link } from "react-router-dom";

export default function Sidebar() {

    const currentPath = useLocation().pathname;

    return (
        <aside className={`w-64 md:border-r md:border-gray-800 p-6 flex flex-col fixed min-h-full items-center`}>
        <div className="mb-8 flex flex-col items-center">
            {/* <img
                alt="Profile"
                className="rounded-full border-2 border-gray-800"
                height="64"
                src="/5a255ed1-cd81-4c89-986b-f43ac408a85e.jpg"
                style={{
                    aspectRatio: "64/64",
                    objectFit: "cover",
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
                width="64"
            /> */}
            <h2 className="mt-4 text-lg font-semibold special">{ctns.name}</h2>
            <p className="text-gray-400 text-sm">{ctns.jobTitle}</p>
            <div className="flex gap-4 mt-4">
                <Link title="Github" to="https://github.com/Triaster" target="_blanc" className="text-gray-400 hover:text-white">
                    <Github className="h-5 w-5" />
                </Link>
                <Link title="Telegram" to="https://t.me/mbrutsky" target="_blanc" className="text-gray-400 hover:text-white">
                    <Send className="h-5 w-5" />
                </Link>
                <Link title="E-mail" to="mailto:brutsky.ms@gmail.com" target="_blanc" className="text-gray-400 hover:text-white">
                    <Mail className="h-5 w-5" />
                </Link>
            </div>
        </div>
        <nav className="space-y-4 flex-1 w-full">
            {menuList.map((item: MenuListItem) => (
                <Link
                    key={item.id}
                    className={`flex items-center select-none transition-all gap-3 text-gray-400 hover:text-white hover:opacity-85 hover:bg-zinc-800 px-3 py-2 rounded-lg ${item.link === currentPath ? 'bg-zinc-900' : ''}`}
                    to={item.link}
                >
                    {item.icon}
                    {item.title}
                </Link>
            ))}
        </nav>
        <Button variant="ghost" size="icon" className="rounded-full mt-auto">
            <Moon className="h-5 w-5" />
            <span className="sr-only">Toggle theme</span>
        </Button>
    </aside>
    )
}