import { useState, useEffect } from 'react';

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Функция для проверки ширины экрана
        const checkMobile = () => setIsMobile(window.innerWidth < 771);

        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        // Удаляем обработчик при размонтировании
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return isMobile;
}