import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import LayoutMain from "@/layouts/LayoutMain"
import {Link} from 'react-router-dom';
import Footer from "@/components/Footer";


export default function HomePage() {
  return (
    <LayoutMain>
              <div className="max-w-[56rem] w-full">
          <h1 className="text-5xl font-bold mb-4">
            Hello! I'm Maxim 👋
          </h1>
          <h2 className="text-3xl text-gray-400 mb-6">
            FullStack Developer
          </h2>
          <p className="text-gray-400 text-lg mb-8">
            Welcome! I'm Maxim Brutsky, a fullStack developer specializing in Next.js and Nest.js. I build high-performance, scalable web applications that solve real-world challenges. Passionate about modern web technologies, I turn ideas into impactful digital experiences. Whether you need an interactive user interface or a robust server architecture, let's collaborate to bring your vision to life.
          </p>
            <Link to="/about">
              {/* <Button variant={'secondary'} className="mb-16">
              More about me
              </Button> */}
            </Link>

          <section>
            <h3 className="text-2xl font-semibold mb-8">Selected Work</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Card className="bg-gray-900 border-gray-800">
                <img
                  alt="Project 1"
                  className="w-full h-auto select-none"
                  height="300"
                  src="/karsten-winegeart-LbtGpw6iid0-unsplash.jpg"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                  }}
                  width="400"
                />
              </Card>
              <Card className="bg-gray-900 border-gray-800">
                <img
                  alt="Project 2"
                  className="w-full h-auto select-none"
                  height="300"
                  src="/rob-potter-KuI8_i40SUg-unsplash.jpg"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                  }}
                  width="400"
                />
              </Card>
            </div>
          </section>

          <Footer />
        </div>
    </LayoutMain>
  )
}