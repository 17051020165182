import { Card } from "@/components/ui/card"
import LayoutMain from "@/layouts/LayoutMain"

import { Link } from "react-router-dom"
import Footer from "@/components/Footer"


export default function WorkPage() {
  return (
    <LayoutMain>
        <div className="max-w-[56rem] w-full">
          <h1 className="text-5xl font-bold mb-4">Work</h1>
          <h2 className="text-xl text-gray-400 mb-12">Selected highlights of my career</h2>

          <div className="grid md:grid-cols-2 gap-8">
            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Lemon Squeezy"
                  className="w-full h-auto"
                  height="300"
                  src="/karsten-winegeart-LbtGpw6iid0-unsplash.jpg"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                    background: "linear-gradient(to bottom right, #8B5CF6, #6D28D9)"
                  }}
                  width="600"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-white">Lemon Squeezy</h3>
                  <p className="text-gray-400">Digital Ecommerce Platform</p>
                </div>
              </Card>
            </Link>

            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="SpinupWP"
                  className="w-full h-auto"
                  height="300"
                  src="/karsten-winegeart-LbtGpw6iid0-unsplash.jpg"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                    background: "linear-gradient(to bottom right, #EF4444, #DC2626)"
                  }}
                  width="600"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-white">SpinupWP</h3>
                  <p className="text-gray-400">WordPress Hosting Control Panel</p>
                </div>
              </Card>
            </Link>

            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Dunked"
                  className="w-full h-auto"
                  height="300"
                  src="/rob-potter-KuI8_i40SUg-unsplash.jpg"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                    background: "linear-gradient(to bottom right, #374151, #1F2937)"
                  }}
                  width="600"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-white">Dunked</h3>
                  <p className="text-gray-400">Portfolio Builder</p>
                </div>
              </Card>
            </Link>

            <Link to="#" className="group">
              <Card className="bg-zinc-900 border-gray-800 overflow-hidden transition-transform group-hover:scale-[1.02]">
                <img
                  alt="Nivo Slider"
                  className="w-full h-auto"
                  height="300"
                  src="/rob-potter-KuI8_i40SUg-unsplash.jpg"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                    background: "linear-gradient(to bottom right, #3B82F6, #2563EB)"
                  }}
                  width="600"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-white">Nivo Slider</h3>
                  <p className="text-gray-400">jQuery & WordPress Plugin</p>
                </div>
              </Card>
            </Link>
          </div>

          <Footer />
        </div>
    </LayoutMain>
  )
}