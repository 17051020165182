import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './routes/home';
import AboutPage from './routes/about/page';
import ContactPage from './routes/contact/page';
import ProjectsPage from './routes/projects/page';
import StorePage from './routes/store/page';
import BlogPage from './routes/blog/page';
import WorkPage from './routes/work/page';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/blog" element={<BlogPage />} />
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
