import { ctns } from '@/lib/cnts'

import { Card } from "@/components/ui/card"
import LayoutMain from "@/layouts/LayoutMain"
import Footer from "@/components/Footer";

export default function AboutPage() {
  return (
    <LayoutMain>
        <div className="max-w-[56rem] w-full">
          <div className="flex flex-col items-center md:items-start md:flex-row gap-8 mb-12">
            <div className="flex-1">
              <h1 className="text-5xl font-bold mb-4">{ctns.name}</h1>
              <h2 className="text-2xl text-gray-400 mb-6">{ctns.jobTitle}</h2>
              <p className="text-gray-400 mb-6">
                Hello! My name is Maxim Brutsky, and I'm a web developer with deep knowledge of Next.js and Nest.js. My passion is creating high-performance and scalable web applications that solve real user problems.
              </p>
              <p className="text-gray-400 mb-6">
                Throughout my career, I've worked on a variety of projects—from developing interactive user interfaces to building reliable server architectures. I always strive to stay updated with the latest trends in the world of web technologies and continuously improve my skills.
              </p>
              <p className="text-gray-400">
                In my free time, I enjoy exploring new frameworks and programming languages, as well as participating in developer communities. I believe in the power of teamwork and am open to collaborating on interesting and innovative projects.
              </p>
            </div>
            <img
              alt="Profile"
              className="rounded-lg select-none"
              height="200"
              src="/5a255ed1-cd81-4c89-986b-f43ac408a85e.jpg"
              style={{
                aspectRatio: "1",
                objectFit: "cover",
              }}
              width="200"
            />
          </div>

          {/* Section Awards */}

          {/* <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6">Awards</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {ctns.awards.map((item) => (
                <Card key={item.id} className="p-6 bg-zinc-900 border-gray-800">
                <h3 className="font-semibold mb-2 text-white">{item.title}</h3>
                <p className="text-gray-400 text-sm">{item.subTitle}</p>
              </Card>
              ))}

            </div>
          </section> */}

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6">Experience</h2>
            <div className="space-y-6">
              {ctns.experience.map((item) => (
                <div key={item.id} className="flex gap-4">
                    <div className="w-32 flex-shrink-0">
                      <div className="text-gray-400">{item.date}</div>
                    </div>
                    <div>
                      <h3 className="font-semibold">{item.title}</h3>
                      <div className="text-gray-400">{item.subTitle}</div>
                    </div>
                </div>
              ))}

            </div>
          </section>

          {/* Education */}

          {/* <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6">Education</h2>
            <div className="space-y-6">
              {ctns.education.map((item) => (
                  <div key={item.id} className="flex gap-4 flex-col sm:flex-row">
                    <div className="w-32 flex-shrink-0">
                      <div className="text-gray-400">{item.date}</div>
                    </div>
                    <div>
                      <h3 className="font-semibold">{item.title}</h3>
                      <div className="text-gray-400">{item.subTitle}</div>
                    </div>
                </div>
              ))}
            </div>
          </section> */}

          <section>
            <h2 className="text-2xl font-semibold mb-6">Skills</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {ctns.skills.map((item) => (
                  <Card key={item.id} className="p-4 bg-zinc-900 border-gray-800 hover:border-gray-100">
                    <h3 className="font-semibold mb-2 text-white">{item.title}</h3>
                    <div className="text-gray-400 text-sm">{item.subTitle}</div>
                  </Card>
              ))}

            </div>
          </section>

          <Footer />
        </div>
    </LayoutMain>
  )
}